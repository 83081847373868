<template>
	<div :class="themeClass" class="width-fill">
		<div class="bg-img flex-row-center-center">
			<div class="open-box text-center bg-fff radius10 flex-column-center-center">
				<div class="font-color-FF6600 font-size34 font-weight700 margin-b-20">欢迎加入聚材通平台</div>
				<div class="font-size18 margin-b-30">开一家属于自己的店铺 获得更多收益</div>
				<div class="flex-row" style="justify-content: space-around;width: 85%;">
					<div class="open-btn box-sizing bg-FF6600 radius30 font-size16 font-color-fff pointer" @click="toFactoryForm">工厂店铺入驻</div>
					<div class="open-btn box-sizing bg-FF6600 radius30 font-size16 font-color-fff pointer" @click="toDistributorForm">零售商店铺入驻</div>
				</div>
				<!-- <div class="open-btn box-sizing bg-FF6600 radius30 font-size16 font-color-fff pointer" style="width: 260px;" @click="toFactoryForm">申请开店</div> -->
			</div>
		</div>
		<div class="tips-box bg-F8F9FE flex-column-center-center">
			<div class="font-color-FF6600 font-size26 font-weight700 margin-b-50">如何在聚材通平台申请开通店铺</div>
			<div class="flex-row">
				<div class="flex-colum text-center">
					<div class="flex-row-align-center">
						<div class="circular-box font-color-fff font-size48 margin-b-15 margin-r-30">1</div>
						<div class="font-color-FF6600 font-size30 margin-r-30">••••••</div>
					</div>
					<div class="font-size18 font-weight700 font-color-FF6600 step-box">登录 / 注册</div>
					<div class="step-box font-color-999">新用户注册聚材通账号 老用户直接登录
					</div>
				</div>
				<div class="flex-colum text-center">
					<div class="flex-row-align-center">
						<div class="circular-box font-color-fff font-size48 margin-b-15 margin-r-30">2</div>
						<div class="font-color-FF6600 font-size30 margin-r-30">••••••</div>
					</div>
					<div class="font-size18 font-weight700 font-color-FF6600 step-box">提交审核</div>
					<div class="step-box font-color-999">填写开店基本信息 填写合法资质信息 提交审核</div>
				</div>
				<div class="flex-colum text-center">
					<div class="flex-row-align-center">
						<div class="circular-box font-color-fff font-size48 margin-b-15 margin-r-30">3</div>
						<div class="font-color-FF6600 font-size30 margin-r-30">••••••</div>
					</div>
					<div class="font-size18 font-weight700 font-color-FF6600 step-box">通过预审</div>
					<div class="step-box font-color-999">基本信息审核</div>
				</div>
				<div class="flex-colum text-center">
					<div class="flex-row-align-center">
						<div class="circular-box font-color-fff font-size48 margin-b-15 margin-r-30">4</div>
						<div class="font-color-FF6600 font-size30 margin-r-30">••••••</div>
					</div>
					<div class="font-size18 font-weight700 font-color-FF6600 step-box">回传资料</div>
					<div class="step-box font-color-999">回传资料并缴纳保证金</div>
				</div>
				<div class="flex-colum text-center">
					<div class="flex-row-align-center">
						<div class="circular-box font-color-fff font-size48 margin-b-15">5</div>
					</div>
					<div class="font-size18 font-weight700 font-color-FF6600 step-box">申请成功</div>
					<div class="step-box font-color-999">将协议原件和资质寄到聚材公司 审核通过后开通店铺</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex';
	export default {
		data() {
			return {

			}
		},
		computed: {
			...mapGetters(['getThemeName']),
			themeClass() {
				return `theme-${this.getThemeName}`;
			}
		},
		methods: {
			//非自营工厂店开店表单
			toFactoryForm() {
				this.$router.push('/OpenShop/OpenShopForm')
			},
			//经销商店铺开店表单
			toDistributorForm() {
				this.$router.push('/OpenShop/OpenDistributorShopForm')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.color-theme {
		@include themify($themes) {
			color: themed('themes_color');
		}
	}

	.background-color-theme {
		@include themify($themes) {
			background-color: themed('themes_color');
			color: themed('text_color_white');
		}
	}

	.border-theme {
		@include themify($themes) {
			border: 1px solid themed('themes_color');
		}
	}

	.border-bottom-theme {
		@include themify($themes) {
			border-bottom: 1px solid themed('themes_color');
		}
	}

	.bg-img {
		width: 100%;
		height: 442px;
		background-image: url(../../../assets/imgs/HomePage/OpenShop.png);
		background-size: 100% 100%;
	}

	.open-box {
		width: 515px;
		height: 270px;
		background-color: rgba($color: #fff, $alpha: 0.9);
	}

	.open-btn {
		width: 200px;
		height: 48px;
		line-height: 48px;
	}

	.open-btn:hover {
		background-color: #fb9856;
	}

	.tips-box {
		width: 100%;
		height: 400px;
	}

	.step-box {
		width: 120px;
	}

	.circular-box {
		width: 120px;
		height: 120px;
		line-height: 120px;
		background-color: #FACE69;
		border-radius: 50%;
	}
</style>
